<template>
  <div>
    <overlay v-if="!isContent && isLoading"></overlay>
    <!-- <contentDetail
      v-else
      :playerInstance="playerInstance"
      :content="content"
      :closePopup="() => toggleViewContent(false)"
    /> -->
  </div>
</template>

<script>
import { eventBus } from "../../eventBus";

import snackbar from "node-snackbar";
import "../../../node_modules/node-snackbar/dist/snackbar.css";
import { mapGetters, mapMutations } from "vuex";
import { showSnackBar } from '@/utilities';
import Utility from "@/mixins/Utility.js";

export default {
  props: {
    playerInstance: {
      type: Object,
    },
  },
  components: {
    loadingSpinner: () => import(/* webpackChunkName: "loadingSpinner" */ "@/components/Templates/Loading.vue"),
    contentDetail: () => import(/* webpackChunkName: "contentDetail" */ "@/components/Popups/contentDetail.vue"),
    overlay: () => import(/* webpackChunkName: "overlayLight" */ "@/components/Templates/overlayLoader.vue"),
  },

  data() {
    return {
      contentId: null,
      content: null,
      isLoading: false,
      seriesId: null,
      subscriberProfileDetails: null
    };
  },
  computed: {
    ...mapGetters(["getRegActiveDeeplink"]),
    isContent() {
      return this.content == null ? false : true;
    },
  },
  created() {
    this.getContentDetail();

    let profileDetails = localStorage.getItem("subscriberProfileDetails");

    if (profileDetails) {
      this.subscriberProfileDetails = JSON.parse(profileDetails);
    }
  },
  mounted() {
    eventBus.$on(`contentDetail-error-response-${this.contentId}`, (data) => {
      if (data.data.errorcode) {
        console.log("THE ERROR", data.data.er);
        this.isLoading = false;
        this.$router.push("/");

        showSnackBar(this.$t("Content unavailable"),this.$t(DISMISS));
      }
    });

    eventBus.$on(`contentDetail-response-${this.contentId}`, (data) => {
      if (data.data && data.data.objecttype === "CONTENT" && data.data.category === "MOVIE") {
        console.log("The content response =--> ", data.data);
        let content = data.data;
        content.isDeeplink = true;
        this.content = content;
        this.isLoading = false;
      } else if (data.data && data.data.objecttype === "CONTENT") {
        let payload = {
          contentid: data.data.seriesid,
        };

        if (this.contentId) {
          eventBus.$emit("getContentDetail", payload);
        }

        eventBus.$on(`contentDetail-response-${data.data.seriesid}`, (data) => {
          console.log("THIS IS SERIES RESPONSE -->", data.data);
          let currentLanguage = this.getCurrentLanguageUrlBase();

          let content = data.data;
          content.isDeeplink = true;
          this.content = content;

           if ( this.content.category == "TVSHOW") {
              let title;
              if (this.localDisplayLang == "eng") {
                title = this.spacialCharEncoding(this.content.title);
              } else {
                title = "title";
              }

              this.$router.push({
                name: "detailPage",
                params: { contentId:payload.contentid  , mediaTitle: title, lang: currentLanguage  },
              });
            } else {
              let title;
              if (this.localDisplayLang == "eng") {
                title = this.spacialCharEncoding(this.content.title);
              } else {
                title = "title";
              }

              this.$router.push({
                name: "detailPageMovie",
                params: { contentId: payload.contentid , mediaTitle: title, lang: currentLanguage  },
              });
            }
        //  this.$router.push({ name: "detailPage", params: { contentId: payload.contentid , mediaTitle:content.title  } });
          eventBus.$off(`contentDetail-response-${data.data.seriesid}`);
        });

        eventBus.$on(`contentDetail-error-response-${this.seriesid}`, (data) => {
          if (data.data.errorcode) {
            console.log("THE ERROR", data.data.er);
            this.isLoading = false;
            this.$router.push("/");

            showSnackBar(this.$t("Content unavailable"),this.$t(DISMISS));

          }
        });
      } else if (data.data && data.data.objecttype !== "CONTENT") {
        console.log("The content response =--> ", data.data);
        let content = data.data;
        content.isDeeplink = true;
        this.content = content;
        this.isLoading = false;
      } else {
        this.content = null;
        this.isLoading = false;
      }
    });
  },
  methods: {
    ...mapMutations(["setRegActiveDeeplink"]),
    getContentDetail() {
      this.isLoading = true;
      this.contentId = this.$route.params.contentId;

      console.log("This content id --->", this.contentId);

      let payload = {
        contentid: this.contentId,
      };

      if (this.contentId) {
        eventBus.$emit("getContentDetail", payload);
      }
    },
    toggleViewContent(state) {
      if (!state) {
        this.$router.push("/");
        if (this.getRegActiveDeeplink) {
          setTimeout(() => {
            let payload = { listType: "onlySubscribe" };
            this.setRegActiveDeeplink(false);
            eventBus.$emit("subscribePopup", payload);
          }, 100);
        }
      }
    },
  },
  beforeDestroy() {
    eventBus.$off(`contentDetail-response-${this.contentId}`);
    eventBus.$off(`contentDetail-error-response-${this.contentId}`);
  },
    mixins: [Utility],
};
</script>

<style lang="scss"></style>
